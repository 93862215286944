<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-form-group :label="`*` + $t('Title') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  v-model="model.title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Title')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
          <Fieldset id="gallery" :title="$t('Gallery')">
            <b-row>
              <!-- <b-col md="3">
                <p><b>*{{ $t('Photos') }}</b></p>
                <p class="text-muted">
                  {{ $t('Tips') }}:
                </p>
                <ul>
                  <li> {{ $t('Use natural light and no flash') }}</li>
                  <li> {{ $t('Include a common object for scale') }}</li>
                  <li> {{ $t('Show the item being held, worn, or used') }}</li>
                  <li> {{ $t('Shoot against a clean, simple background') }}</li>
                </ul>
              </b-col> -->
              <b-col md="12">
                <div class="d-flex align-items-center">
                  <p class="flex-grow-1">
                    {{ $t('We recommend adding 3 more photos') }}
                  </p>
                  <p @click="handlerDeleteAll">
                    {{ $t('Delete All') }}
                  </p>
                </div>
                <b-row>
                  <b-col
                    md="3"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <b-aspect aspect="1:1">
                        <b-img-lazy
                          v-if="model.avatar && model.avatar !== null"
                          :src="model.avatar"
                          fluid
                          thumbnail
                          rounded
                          class="w-100"
                          style="object-fit: contain;"
                        />
                      </b-aspect>
                    </div>
                  </b-col>
                  <b-col md="9">
                    <draggable
                      v-model="model.list_thumbnail"
                      tag="ul"
                      class="cursor-move d-flex flex-wrap"
                    >
                      <b-col
                        v-for="(item, index) in model.list_thumbnail"
                        :key="index"
                        class="review-img mb-1"
                        md="3"
                      >
                        <b-button
                          variant="danger"
                          class="review-img__del btn-icon rounded-circle"
                          @click.stop="delImg(index)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <b-aspect aspect="1:1">
                          <b-img-lazy
                            :src="item"
                            fluid
                            thumbnail
                            rounded
                            class="w-100"
                            style="object-fit: contain;"
                          />
                        </b-aspect>
                      </b-col>
                    </draggable>
                  </b-col>
                  <b-col md="12" class="pt-1">
                    <div
                      class="fade-browse text-center align-items-center justify-content-center w-100 p-2 rounded"
                    >
                      <p class="pt-1">
                        {{ $t('Add upto 12 photos so buyers can see every detail') }}
                      </p>
                      <div class="d-flex flex-wrap">
                        <b-col md="6">
                          <b-form-file
                            id="input-product"
                            multiple
                            accept=".jpg, .png, .gif, .jpeg"
                            class="img-box"
                            @change="previewFiles"
                          />
                          <label for="input-product" class="btn-add">
                            <feather-icon icon="UploadCloudIcon" />
                            {{ $t('Upload from your PC') }}
                          </label>
                        </b-col>
                        <b-col md="6">
                          <!-- <b-form-file
                      id="input-product"
                      multiple
                      accept=".jpg, .png, .gif, .jpeg"
                      class="img-box"
                      @change="previewFiles"
                    /> -->
                          <label class="btn-add">
                            <feather-icon icon="PlusCircleIcon" />
                            {{ $t('Add from Media center') }}
                          </label>
                        </b-col>
                      </div>
                    </div>
                    <!-- <p
                      class="text-muted mt-1 text-center mx-auto"
                      style="font-size: 12px;"
                    >
                      {{ $t('Add up to 12 photos. We do not allow photos with extra borders, text or artwork') }}
                    </p> -->
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="category" :title="$t('Category')">
              <div class="d-flex align-items-center">
                <validation-provider
                  #default="{ errors }"
                  name="Gallery category"
                  rules="required"
                  class="w-100 mr-1"
                >
                  <v-select
                    v-if="CategoryOptions"
                    v-model="model.gallery_category_id"
                    label="title"
                    :reduce="x => x.id"
                    :options="CategoryOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link>
                  <feather-icon v-b-modal.modal-gallery-cate icon="PlusCircleIcon" size="28" />
                </b-link>
              </div>
            </Fieldset>
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  :placeholder="$t('Position')"
                />
              </b-form-group>
              <br>
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Create new') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
      <b-modal
        id="modal-gallery-cate"
        centered
        size="xl"
        title="Add new gallery category"
      >
        <GalleryCateAdd />
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormFile,
  BImgLazy,
  BAspect,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import GalleryCateAdd from './GalleryCategoryAdd.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BForm,
    BFormFile,
    BImgLazy,
    BAspect,
    ValidationProvider,
    ValidationObserver,
    draggable,
    BFormCheckbox,
    BLink,
    GalleryCateAdd,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        title: null,
        position: null,
        is_active: true,
        list_thumbnail: [],
        gallery_category_id: null,
      },
      CategoryOptions: null,
    }
  },
  async mounted() {
    this.CategoryOptions = await this.loadBlogCategoryList()
  },
  methods: {
    handlerChangeAvatar(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    handlerDeleteAll() {
      this.model.avatar = null
      this.model.list_thumbnail = []
    },
    delImg(index) {
      this.model.list_thumbnail.splice(index, 1)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              title: this.model.title,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              gallery_category_id: this.model.gallery_category_id,
              title: this.model.title,
              translation: JSON.stringify(translation),
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  type_table: 'g_m',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1
                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadBlogCategoryList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/gallery_categories?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    previewFiles(input) {
      let i = 0
      if (input.target.files.length + this.model.list_thumbnail.length <= 12) {
        for (i; i < input.target.files.length; i++) {
          const file = input.target.files[i]
          const reader = new FileReader()
          if (i > 0) {
            reader.onload = e => {
              this.model.list_thumbnail.push(e.target.result)
            }
          } else {
            reader.onload = e => {
              if (this.model.avatar !== null) {
                this.model.list_thumbnail.push(e.target.result)
              } else {
                this.model.avatar = e.target.result
              }
            }
          }
          reader.onerror = error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
          reader.readAsDataURL(file)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Maximum is 12 picture'),
          },
        })
      }
    },
    async handleCreate(params) {
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/gallery`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Gallery success',
            },
          })
          this.$router.push('/gallery/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review-img,
.review-img-avatar {
  position: relative;

  &::before {
    position: absolute;
    content: 'Drag to Sort';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del,
    .review-img__del_avatar {
      opacity: 1;
    }
  }
}
.review-img-avatar {
  &::before {
    content: '';
    width: 100%;
    left: 0;
  }
  .review-img__del_avatar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    z-index: 1;
    width: 100%;
    min-height: 3rem;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
}
</style>
